/* You can add global styles to this file, and also import other style files */


/* @import "~@angular/material/prebuilt-themes/indigo-pink.css"; */


/* @import "~bootstap/dist/css/bootstrap.min.css"; */

body {
    margin: 0%;
}

.red-snackbar {
    background: #b00020;
}

::ng-deep.mat-dialog-container {
    overflow: hidden;
}


/*
.customAttachEmail.mat-dialog-container {
    padding: 0px;
    box-shadow: 0 12px 11px 0 rgba(0, 0, 0, 0.08);
    border-radius: 6px;
} */

.previewDialog .mat-dialog-container {
    padding: 0px 0px 0px 0px !important;
}
.myPanelClass{
    margin-top: 30px !important;
}
.no-arrow .mat-select-arrow {
    display: none;
}