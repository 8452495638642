@import "~@angular/material/theming";
@include mat-core();
$red-palette: (
  50: #0183fb,
  100: #97cb64,
  700: #97cb64,
  contrast: (
    50: rgba(black, 0.87),
  ),
);
$ez-app-primary: mat-palette($red-palette, 50);
$ez-app-accent: mat-palette($red-palette, 100);
// The warn palette is optional (defaults to red).
$ez-app-warn: mat-palette($mat-red);
// Create the theme object (a Sass map containing all of the palettes).
$ez-app-theme: mat-light-theme($ez-app-primary, $ez-app-accent, $ez-app-warn);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($ez-app-theme);

@font-face {
  font-family: "Nunito Sans";
  src: url("../fonts/NunitoSans-VariableFont_YTLC\,opsz\,wdth\,wght.ttf");
}

:root {
  --primary-color: #4379EE;
  --secondary-color: #edf0f2;
  --border-color: #636466;
  --primary-faded-color: rgba(1, 131, 251, 0.4);

  //main colors
  --main-blue: #4379EE;
  //main background colors
  --main-bg-white: #FFFFFF;
  --main-bg-bgrey: #F1F4F9;
  --main-bg-bgrey2: #FAFBFD;
  --main-bg-grey: #E8E8E8;
  --main-bg-pink: #FD5454;
  --main-bg-green: #00B69B;
  --main-bg-yellow: #FCBE2D;
  //main border colors
  --main-brd-grey: #D5D5D5;
  --main-brd-grey2: #E9E9E9; //disabled style
  //main font colors
  --main-font-black: #202224;
  --main-font-grey: #4D4E50;
  --main-font-grey: #B0B0B0; //disabled style
  --main-font-grey2: #606060;
  --main-font-red: #ff4500;
  //shadow
  --main-shardow-1: 6px 6px 54px 0px #0000000D;
  // button border
  --main-bg-blue2: #4880FF;
  --store-background-color: #E5E4FF;
  --slidder-primary-color: #00B69B;
  --slidder-secondary-color: #8280FF;
}

.button-hover-effect:hover {
  box-shadow: 0px 0px 7px 2px #afb7b72e;
  -webkit-box-shadow: 0px 0px 7px 2px #afb7b72e;
  scale: 1.1;
  transition: 0.2s;
}

.main-white-panel {
  background: #ffffff;
  padding: 13px;
  border-radius: 13px;
  box-shadow: 0px 4px 5px 0px #0000000f;
}

.main-label-style {
  color: var(--main-font-grey2);
  margin-bottom: 7px;
  font-weight: 600;
}



.snackBarStyle {
  color: #fff;
}

.customAttachEmail .mat-dialog-container {
  padding: 0px;
  box-shadow: 0 12px 11px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}

.offerSummarydialog .mat-dialog-container {
  padding: 0px;
  box-shadow: 0 12px 11px 0 rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

.no-padding-dialog .mat-dialog-container {
  padding: 20px;
  box-shadow: 0 12px 11px 0 rgba(0, 0, 0, 0.08);
  border-radius: 6px;
}

.strategy-dialog-backdrop .cdk-overlay-dark-backdrop {
  background: #ffffff00;
}

.tooltip-backdrop .cdk-overlay-dark-backdrop {
  background: #ffffff00;
}

.no-padding-dialog-popup .mat-dialog-container {
  padding: 0px;
  border-radius: 14px;
  background: #ffffff;
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
}

.no-padding-no-border-radius-dialog .mat-dialog-container {
  background: #ffffff;
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 0px;
}


.o-item-dialog .mat-dialog-container {
  padding: 0px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
  overflow-y: hidden;
}
.tooltip-dialog .mat-dialog-container {
  padding: 5px;
  box-shadow: 0 12px 11px 0 rgba(0, 0, 0, 0.08);
  border-radius: 2px;
}

.tooltip-noPadding .mat-dialog-container {
  padding: 0px;
  box-shadow: 0 12px 11px 0 rgba(0, 0, 0, 0.08);
  border-radius: 2px;
}

.leads-dialog .mat-dialog-container {
  padding: 0px;
  box-shadow: 0 12px 11px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  overflow: hidden;
}

.tooltip-class {
  font-size: 12px;
  padding: 5px;
  box-shadow: 0 12px 11px 0 rgba(0, 0, 0, 0.08);
  border-radius: 2px;
}

button:focus,
button:active:focus,
button.active:focus {
  outline: none !important;
  outline-style: none !important;
}

.mat-button-focus-overlay {
  background-color: transparent !important;
}

* {
  font-family: "Nunito Sans";
}

.customer-profile-dialog-container {
  max-width: 95vw !important;
}

.customer-profile-dialog-container .mat-dialog-container {
  padding: 0px;
  margin: 0px;
  width: 100vw !important;
}

.custom-dialog-container .mat-dialog-container {
  padding: 0px;
  width: 350px;
  border-radius: 14px;
  box-shadow: var(--main-shardow-1);

}

.create-sms-dialog-container .mat-dialog-container {
  padding: 0px;
  margin: 0px;
  border-radius: 14px;
  overflow: hidden;
  box-shadow: var(--main-shardow-1);
}

.schedule-sms-dialog-container .mat-dialog-container {
  padding: 0px;
  margin: 0px;
  border-radius: 4px;
}

.sms-preview-dialog .mat-dialog-container {
  background: none;
  background: none;
  background-color: transparent;
  box-shadow: none;
  padding: 0px;
}

.homescreen-dialog-container .mat-dialog-container {
  padding: 0px;
  border-radius: 0px;
  position: relative;
}

.white-snackbar {
  background-color: white;
  color: black;
}
.ex-tooltip-red1 {
  word-break: break-all !important;
  white-space: normal !important;
}

html {
  font-size: 14px;
}

.primary-button {
  background: var(--main-blue);
  padding: 8px 20px;
  height: 40px;
  color: #fff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  border: none;
  outline: none;
}

.primary-button-disabled {
  background: var(--primary-color);
  opacity: 0.4;
  padding: 8px 20px;
  height: 40px;
  color: #fff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
}

.primary-button-border {
  background: #fff;
  padding: 8px 20px;
  height: 40px;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.secondary-button {
  background: var(--secondary-color);
  color: #222222;
  padding: 8px 20px;
  height: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.secondary-button-border {
  background: #fff;
  padding: 8px 20px;
  height: 40px;
  color: var(--main-blue);
  border: 1px solid var(--main-blue);
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 700;
}

.cursor-pointer{
  cursor: pointer;
}
.mat-pseudo-checkbox-checked{
  background: #0183fb !important;
}

.icons-primary {
  color: #636466;
}

.button-icon {
  color: #ffffff;
  font-size: 20px;
  height: 20px;
  width: 20px;
}

.full-width-dialog .mat-dialog-container {
  padding: 0px;
  box-shadow: 0 12px 11px 0 rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  width: 500px !important;
}

::-webkit-scrollbar {
  width: 14px;
  height: 18px;
}

::-webkit-scrollbar-thumb {
  min-height: 12px;
  height: 12px;
  border: 3px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  border-radius: 7px;
  background-color: rgba(0, 0, 0, 0.15);
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}
.gf-backdropBackground{
  backdrop-filter: blur(2px);
}

.qb-d-flex{
  display: flex;
}
.qb-d-block{
  display: block;
}
.qb-d-inline-flex{
  display: inline-flex;
}
.qb-jc-space-between{
  justify-content: space-between;
}

.qb-ai-baseline{
  align-items: baseline;
}
.qb-jc-flex-end{
  justify-content: flex-end;
}
.qb-jc-flex-start{
  justify-content: flex-start;
}
.qb-jc-center{
  justify-content: center;
}
.qb-ai-center{
  align-items: center;
}
.qb-ai-flex-start{
  align-items: flex-start;
}
.qb-ai-flex-end{
  align-items: flex-end;
}
.qb-w-100{
  width: 100%;
}
.qb-h-100{
  height: 100%;
}
.qb-w-auto{
  width: auto;
}
.qb-p-relative{
  position: relative;
}
.qb-p-fixed{
  position: fixed;
}
.qb-fd-coloumn{
  flex-direction: column;
}
.qb-text-lower-case{
  text-transform: lowercase
}
.qb-text-capitalize{
  text-transform: capitalize
}
.qb-ta-center{
  text-align: center;
}
.qb-not-allowed{
  cursor: not-allowed;
}
.qb-td-underline{
  text-decoration:underline
}
.qb-ai-stretch{
  align-items: stretch;
}
.qb-text-align-end{
  text-align: end;
}
.qb-position-absolute{
  position: absolute;
}

.qb-gap-4{
  gap: 4px;
}

.qb-gap-8{
  gap: 8px;
}

.qb-w-49 {
  width: 49%;
}

.qb-flex-1 {
  flex: 1;
}

.customer-dialog-popup .mat-dialog-container {
  background: #ffffff;
  border: 1px solid #e9eef5;
  box-shadow: 8px 8px 16px rgba(40, 69, 199, 0.05);
  padding: 24px;
  border-radius: 32px;
}

.activity-spinner-container {
  display: flex;
  justify-content: center; /* optional */
  align-items: center;
  height: 100%;
}

.module-heading {
  font-weight: 700;
  letter-spacing: -0.11px;
  font-size: 1.75rem;
}

.qb-error-message{
  color: #d71920;
  margin-top: 4px;
  font-size: 12px
}

.qb-text-transform-capitalize{
  text-transform: capitalize;
}

@media (max-width: 896px) {
  ::-webkit-scrollbar {
    width: 5px;
    height: 18px;
  }

  ::-webkit-scrollbar-thumb {
    min-height: 12px;
    height: 12px;
    border: 0px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    background-color: rgba(0, 0, 0, 0.15);
  }

  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}
